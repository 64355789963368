<template>
      <div>
    <!-- Medikament -->
    <div>
      <div class="trennlinie">
      </div>
      <div class="margintop25 marginbottom25">
        <div class="container">
          <div class="row">
            <div class="col-2 center">
              <img src="../assets/icons/werteerfassung.svg">
            </div>
            <div class="col-10 col-center left">
              <p class="pbold fontcolor-white">{{monitoringtype}}</p>
              <p class="opacity40 pregular fontcolor-white">{{monitoringrhythm}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  props: ['monitoringtype', 'monitoringrhythm']
}
</script>