<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <a class="col-2" href="">
          </a>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-white">Therapieplan</p>
          </div>
          <div class="col-2 col-right">
          </div>
        </div>
      </div>
    </div>

    <div class="margintop140 container todolistueberschrift marginbottom20">
      <p class="pregular fontcolor-white opacity80">Medikationsplan</p>
    </div>
    <div class="aufgabelist">
      <therapieplan-listelement-medication v-for='(listmedication, i) in getMedikationinfos' :key="listmedication.id" :med_id="i" :medicationname="listmedication.name" :takingdosemorning="listmedication.einnahmezeiten.frueh.zahl" :takingdosenoon="listmedication.einnahmezeiten.mittag.zahl" :takingdoseevening="listmedication.einnahmezeiten.abend.zahl" :takingdosenight="listmedication.einnahmezeiten.nacht.zahl"></therapieplan-listelement-medication>
      <!--<therapieplan-listelement-medication medicationname="Myfortic" takingrhythm="Täglich" takingdosemorning="2" takingdosenoon="0" takingdoseevening="2" takingdosenight="0"></therapieplan-listelement-medication>
      <therapieplan-listelement-medication medicationname="Decortin" takingrhythm="Täglich" takingdosemorning="2" takingdosenoon="0" takingdoseevening="2" takingdosenight="0"></therapieplan-listelement-medication> -->
    </div>

    <div class="margintop40 container todolistueberschrift marginbottom20">
      <p class="pregular fontcolor-white opacity80">Monitoringplan</p>
    </div>
    <div class="aufgabelist" style="margin-bottom: 120px">
      <therapieplan-listelement-monitoring monitoringtype="Temperatur" monitoringrhythm="Täglich"></therapieplan-listelement-monitoring>
      <therapieplan-listelement-monitoring monitoringtype="Gewicht" monitoringrhythm="Täglich"></therapieplan-listelement-monitoring>
      <therapieplan-listelement-monitoring monitoringtype="Blutdruck" monitoringrhythm="Täglich"></therapieplan-listelement-monitoring>
      <therapieplan-listelement-monitoring monitoringtype="Befinden" monitoringrhythm="Täglich"></therapieplan-listelement-monitoring>
      <therapieplan-listelement-monitoring monitoringtype="Symptome" monitoringrhythm="Täglich"></therapieplan-listelement-monitoring>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>


<script>
  import FootNavigationVue from "../components/FootNavigation.vue";
  import TherapieplanListelementMedicationVue from "../components/TherapieplanListelementMedication.vue";
  import TherapieplanListelementMonitoringVue from "../components/TherapieplanListelementMonitoring.vue";
  import { mapGetters } from 'vuex';

  export default {
    name: 'Therapieplan',
    components: {
      'foot-navigation' :FootNavigationVue,
      'therapieplan-listelement-medication' : TherapieplanListelementMedicationVue,
      'therapieplan-listelement-monitoring' : TherapieplanListelementMonitoringVue,
    },
    computed: {
      ...mapGetters(["getMedikationinfos"]),
    }
  };
</script>


<style>
</style>