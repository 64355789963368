<template>
  <div>
    <!-- Medikament -->
    <div>
      <div class="trennlinie"></div>
      <div class="margintop25 marginbottom25">
        <button style="width:100%" @click="therapieplanzutherapieplandetail(med_id)">
          <div class="container">
            <div class="row">
              <div class="col-2 center">
                <img src="../assets/icons/medikation_white.svg">
              </div>
              <div class="col-5 col-center left">
                <p class="pbold fontcolor-white">{{ medicationname }}</p>
                <p class="pregular fontcolor-white opacity40">{{ takingrhythm }}</p>
              </div>
              <div class="col-3 col-center center">
                <!-- Dosis aus Voreinstellungen -->
                <div class="row">
                  <div class="col-0.5 center">
                    <p class="pregular fontcolor-white">{{ takingdosemorning }}</p>
                  </div>
                  <div class="col-0.5 col-center left">
                    <p class="pregular fontcolor-white">{{ takingdosenoon }}</p>
                  </div>
                  <div class="col-0.5 col-center center">
                    <p class="pregular fontcolor-white">{{ takingdoseevening }}</p>
                  </div>
                  <div class="col-0.5 col-center left">
                    <p class="pregular fontcolor-white">{{ takingdosenight }}</p>
                  </div>
                </div>
              </div>
              <div class="col-2 col-right center">
                <img src="../assets/icons/arrow_right.svg">
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
  import router from '../router';

  export default {
    props: ['med_id','medicationname', 'takingrhythm', 'takingdosemorning', 'takingdosenoon', 'takingdoseevening', 'takingdosenight'],
    methods: {
        therapieplanzutherapieplandetail(id) {
          router.push({path: '/therapieplandetail/' + id})
        }
    },

}
</script>